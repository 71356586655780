'use client';

import { createContext, useContext, useEffect } from 'react';

import { LocaleKey } from '@/constants/locales';
import { usePolyglotPresenterUI } from '@/hooks/polyglot-presenter/usePolyglotPresenterUI';
import { usePolyglotPresenterSession } from '@/hooks/polyglot-presenter/usePolyglotPresenterSession';
import { usePolyglotPresenterReadWaitTime } from '@/hooks/polyglot-presenter/usePolyglotPresenterReadWaitTime';
import { usePolyglotPresenterTtsPortal } from '@/hooks/polyglot-presenter/usePolyglotTtsPortal';

export type PolyglotPresenterContext = {
  localeKey?: LocaleKey;
  ui?: ReturnType<typeof usePolyglotPresenterUI> | undefined;
  activeSession?: ReturnType<typeof usePolyglotPresenterSession> | undefined;
  readWaitTime?: ReturnType<typeof usePolyglotPresenterReadWaitTime>;
  portal?: ReturnType<typeof usePolyglotPresenterTtsPortal>;
};

const PolyglotPresenterContext = createContext<PolyglotPresenterContext>({});

type PolyglotPresenterProviderProps = {
  children: React.ReactNode;
  localeKey: LocaleKey;
  apiKey: string;
  link: string;
  apiServer: string;
  password?: string;
};

export const PolyglotPresenterProvider = (props: PolyglotPresenterProviderProps) => {
  const { children, localeKey, apiKey, link, password } = props;

  const activeSession = usePolyglotPresenterSession(apiKey, link, password);
  const ui = usePolyglotPresenterUI();
  const readWaitTime = usePolyglotPresenterReadWaitTime(activeSession?.timeline);
  const portal = usePolyglotPresenterTtsPortal(ui.view);

  const value = { localeKey, ui, activeSession, readWaitTime, portal };

  useEffect(() => {
    if (activeSession.session) {
      ui.setIsMenuExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSession.session]);

  return (
    <PolyglotPresenterContext.Provider value={value}>
      {portal.portalNode}
      {children}
    </PolyglotPresenterContext.Provider>
  );
};

export function usePolyglotPresenter() {
  return useContext(PolyglotPresenterContext);
}
