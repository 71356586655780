'use client';

import classNames from 'classnames';

import { SpeechAnimation } from '../SpeechAnimation';
import { Funnels } from '../Funnels/Funnels';

import { useTranslation } from '@/hooks/useTranslation';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';
import { DownIcon } from '@/components/icons/icons';
import { Particles } from '@/components/widgets/backgrounds/Particles';

export const Highlights = () => {
  const title = useTranslation(['home', 'title'], 'Speech made simple', {
    nonDefaultLocaleText: 'Simplifying the Transcription Process',
  });
  const pointOne = useTranslation(['home', 'points', 'one'], 'Automatic speech recognition');
  const pointTwo = useTranslation(['home', 'points', 'two'], 'Speech-to-text transcription and translation');
  const pointThree = useTranslation(['home', 'points', 'three'], 'Pre-recorded audio and LiveStream processing');

  return (
    <div className="relative -mt-24 pb-20 pt-20 sm:-mt-40">
      <Particles />

      <a href="#what-is-vocalstack" aria-hidden>
        <DownIcon
          className={classNames(
            'absolute bottom-0 left-0 right-0 mx-auto cursor-pointer overflow-clip rounded-full bg-white fill-emerald-400 translate-y-[50%] hover:shadow-xl dark:bg-background-800',
            'w-20 p-4 lg:w-28 lg:p-5',
          )}
        />
      </a>

      <div className="z-1 relative flex flex-col sm:gap-10">
        <MaxWidthContainer className="flex flex-col items-center text-center lg:gap-10">
          <h1 className="bg-gradient-to-r from-sky-500 to-emerald-500 bg-clip-text p-2 text-xl font-extrabold text-transparent dark:from-sky-400 dark:to-emerald-600 sm:mb-4 sm:mt-20 md:text-4xl lg:text-6xl">
            <SpeechAnimation sequence={[title]} />
          </h1>

          <div className="rounded-xl py-4 sm:p-10">
            <ul className="text-balance flex flex-col gap-4 text-base font-light dark:[text-shadow:_1px_1px_0px_#131326] md:text-2xl">
              <li>{pointOne}</li>
              <li>{pointTwo}</li>
              <li>{pointThree}</li>
            </ul>
          </div>
        </MaxWidthContainer>
        <Funnels />
      </div>
    </div>
  );
};
