import { LocaleKey } from '@/constants/locales';

const MIN_SECONDS = 2;
const MAX_SECONDS = 10;

const estimatedWpmReadingByLocale: Record<LocaleKey, number> = {
  af: 180,
  ar: 150,
  hy: 170,
  az: 180,
  be: 180,
  bs: 200,
  bg: 180,
  ca: 220,
  zh: 170, // Chinese can be tricky (needs more work)
  hr: 200,
  cs: 180,
  da: 220,
  nl: 220,
  en: 200,
  et: 200,
  fi: 200,
  fr: 180,
  gl: 200,
  de: 200,
  el: 180,
  he: 150,
  hi: 180,
  hu: 180,
  is: 180,
  id: 220,
  it: 200,
  ja: 170, // Japanese can be tricky (needs more work)
  kn: 150,
  kk: 180,
  ko: 170,
  lv: 200,
  lt: 200,
  mk: 180,
  ms: 220,
  mr: 180,
  ne: 180,
  no: 220,
  fa: 170,
  pl: 200,
  pt: 220,
  ro: 200,
  ru: 180,
  sr: 180,
  sk: 180,
  sl: 180,
  es: 220,
  sw: 200,
  sv: 220,
  tl: 220,
  ta: 150,
  th: 150,
  tr: 200,
  uk: 180,
  ur: 150,
  vi: 180,
  cy: 200,
};

/**
 * Approximates the error function erf(x).
 * Source of coefficients: Numerical Recipes or similar references.
 */
function erf(x: number): number {
  // Save the sign of x
  const sign = x < 0 ? -1 : 1;
  x = Math.abs(x);

  // Constants in the approximation
  const a1 = 0.254829592;
  const a2 = -0.284496736;
  const a3 = 1.421413741;
  const a4 = -1.453152027;
  const a5 = 1.061405429;
  const p = 0.3275911;

  // A&S formula 7.1.26
  const t = 1.0 / (1.0 + p * x);
  const y = 1.0 - ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

  return sign * y;
}

/**
 * CDF of the normal distribution with mean mu and std dev sigma.
 * This maps (-∞, +∞) to (0, 1).
 */
function normalCDF(x: number, mu: number, sigma: number): number {
  return 0.5 * (1 + erf((x - mu) / (Math.sqrt(2) * sigma)));
}

/**
 * Maps any `time` in seconds onto the range [MIN_SECONDS, MAX_SECONDS]
 * via the normal CDF. As `time` → -∞, result → MIN_SECONDS;
 * as `time` → +∞, result → MAX_SECONDS.
 *
 * @param time - raw reading time (in seconds)
 * @returns a number between MIN_SECONDS and MAX_SECONDS
 */
function gaussianClamp(time: number, min = MIN_SECONDS, max = MAX_SECONDS): number {
  // Compute the CDF value (0..1)
  const MU = (min + max) / 2; // "midpoint" in seconds
  const SIGMA = 1; // controls how quickly it transitions
  const cdfValue = normalCDF(time, MU, SIGMA);

  // Transform CDF (0..1) into [MIN_SECONDS..MAX_SECONDS]
  return min + (max - min) * cdfValue;
}

const cache: Record<string, number> = {};

/**
 * Estimate reading time for a given text and locale,
 * then apply a Gaussian clamp so that short texts can't
 * go below 1s and large texts won't exceed 5s.
 * @param text   The text to be read
 * @param locale The locale/language key
 * @returns      Estimated reading time in seconds (as a float, in range [1..5])
 */
export function estimateReadingTime(text: string, locale: LocaleKey): number {
  const cacheKey = `${locale}-${text}`;
  const cacheResult = cache[cacheKey];
  if (cacheResult) {
    return cacheResult;
  }

  // Number of words in the text
  const words = text.trim().split(/\s+/).length;

  // Fallback to 200 WPM if the locale isn't found for some reason
  const wpm = estimatedWpmReadingByLocale[locale] ?? 200;

  // Raw reading time in seconds (without any clamping)
  const rawReadingTime = (words / wpm) * 60;

  // Apply our Gaussian clamp to get a value from 1..5 seconds
  const adjustedTime = gaussianClamp(rawReadingTime);

  cache[cacheKey] = adjustedTime;

  return adjustedTime;
}
