import { useId } from 'react';

import classNames from 'classnames';

export const DotsBackground = ({
  children,
  className,
  lightBg = '#efefef',
  darkBg = '#131326',
  lightDot = '#333333',
  darkDot = 'rgba(255, 255, 255, 0.15)',
}: {
  children: React.ReactNode;
  className?: string;
  lightBg?: string;
  darkBg?: string;
  lightDot?: string;
  darkDot?: string;
}) => {
  const id = useId().replace(/:/g, '-');
  return (
    <div className={classNames(`dots${id}`, className)}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            html:not(.dark) .dots${id}{
                --dot-bg: ${lightBg};
                --dot-color: ${lightDot};
                --dot-size: 1px;
                --dot-space: 22px;
                background:
                  linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
                  linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
                  var(--dot-color);
            }
            html.dark .dots${id}{
              --dot-bg: ${darkBg};
              --dot-color: ${darkDot};
              --dot-size: 1px;
              --dot-space: 22px;
              background:
                linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
                linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
                var(--dot-color) !important;
          }
          `,
        }}
      />
      {children}
    </div>
  );
};
