import { useCallback, useEffect, useRef, useState } from 'react';

import { Timeline } from '@vocalstack/js-sdk';

import { estimateReadingTime } from '@/utils/estimatedReadingTime';
import { LocaleKey, localeKeys } from '@/constants/locales';

export const usePolyglotPresenterReadWaitTime = (timeline?: Timeline) => {
  const itemWaitStartTimestamps = useRef<Record<number, number>>({});
  const lastVisibleItemIndex = useRef<number>(-1);
  const [lastVisibleItemIndex_state, setLastVisibleItemIndex_state] = useState(-1);
  const [countdownPeriod, setCountdownPeriod] = useState<number>(0);

  const lastVisibleItemIndex_perLocale = useRef<Partial<Record<LocaleKey, number>>>({});
  const translationWaitStartTimestamps = useRef<Record<number, Partial<Record<LocaleKey, number>>>>({});
  const [lastVisibleItemIndexForLocale, setLastVisibleItemIndexForLocale] = useState<
    Partial<Record<LocaleKey, number>>
  >({});
  const [countdownPeriodPerLocale, setCountdownPeriodPerLocale] = useState<Partial<Record<LocaleKey, number>>>({});
  const lastAllTranslationsRef = useRef<LocaleKey[]>([]);

  const reset = () => {
    lastVisibleItemIndex.current = -1;
    setLastVisibleItemIndex_state(-1);
    setCountdownPeriod(0);
    lastVisibleItemIndex_perLocale.current = {};
    translationWaitStartTimestamps.current = {};
    setLastVisibleItemIndexForLocale({});
    setCountdownPeriodPerLocale({});
  };

  useEffect(() => {
    if (!timeline) {
      reset();
    }
  }, [timeline]);

  useEffect(() => {
    if (!timeline) {
      return;
    }
    if (lastVisibleItemIndex.current !== -1) return;
    lastVisibleItemIndex.current = timeline.length - 1;
    setLastVisibleItemIndex_state(timeline.length - 1);
  }, [timeline]);

  useEffect(() => {
    if (!timeline) {
      return;
    }
    const allTimelineTranslations = Array.from(
      new Set(timeline.map((t) => Object.keys(t.translations ?? [])).flat()),
    ) as LocaleKey[];
    if (
      lastAllTranslationsRef.current.length === allTimelineTranslations.length &&
      lastAllTranslationsRef.current.every((locale) => allTimelineTranslations.includes(locale))
    )
      return;
    const newTranslations = allTimelineTranslations.filter(
      (locale) => !lastAllTranslationsRef.current.includes(locale),
    );
    lastAllTranslationsRef.current = allTimelineTranslations;
    newTranslations.forEach((locale) => {
      const lastIndex = timeline.findLastIndex(
        (item) => item.translations?.[locale] && item.translations?.[locale] !== '...',
      );
      if (lastIndex === -1) return;
      lastVisibleItemIndex_perLocale.current[locale] = lastIndex;
      setLastVisibleItemIndexForLocale((prev) => ({ ...prev, [locale]: lastIndex }));
    });
  }, [timeline]);

  const recomputeVisibleItems = useCallback(() => {
    if (!timeline || !timeline[lastVisibleItemIndex.current + 1]) {
      return;
    }

    const lastVisibleItemWaitStartTimestamp = itemWaitStartTimestamps.current[lastVisibleItemIndex.current] || 0;

    if (lastVisibleItemWaitStartTimestamp) {
      const waitTime = Date.now() - lastVisibleItemWaitStartTimestamp;
      const lastVisibleItem = timeline[lastVisibleItemIndex.current];
      const estimatedTime_ms = estimateReadingTime(lastVisibleItem.text, lastVisibleItem.language as LocaleKey) * 1000;

      setCountdownPeriod(estimatedTime_ms / 1000);

      if (waitTime < estimatedTime_ms) {
        const timeoutPeriod = estimatedTime_ms - waitTime + 50;
        setTimeout(() => recomputeVisibleItems(), timeoutPeriod);
        return;
      }
    }

    if (timeline[lastVisibleItemIndex.current + 1]) {
      lastVisibleItemIndex.current++;
      itemWaitStartTimestamps.current[lastVisibleItemIndex.current] = Date.now();
      setLastVisibleItemIndex_state(lastVisibleItemIndex.current);
    }

    if (timeline.length > lastVisibleItemIndex.current + 1) {
      recomputeVisibleItems();
    }
  }, [timeline]);

  const recomputeVisibleTranslations = useCallback(() => {
    if (!timeline || timeline.length === 0) return;

    let runAgain = false;

    (localeKeys as LocaleKey[]).forEach((locale) => {
      if (typeof lastVisibleItemIndex_perLocale.current[locale] === 'undefined') {
        lastVisibleItemIndex_perLocale.current[locale] = timeline.length - 1;
      }
      const index = Number(lastVisibleItemIndex_perLocale.current[locale]);
      const lastVisibleItem = timeline[index];
      const lastVisibleItemTranslation = lastVisibleItem && lastVisibleItem.translations?.[locale];
      const requiresEstimation = lastVisibleItemTranslation && lastVisibleItemTranslation !== '...';

      if (!lastVisibleItem) {
        return;
      }

      if (requiresEstimation) {
        const lastVisibleItemWaitStartTimestamp = translationWaitStartTimestamps.current[index]?.[locale] || 0;

        if (lastVisibleItemWaitStartTimestamp) {
          const waitTime = Date.now() - lastVisibleItemWaitStartTimestamp;
          const estimatedTime_ms = estimateReadingTime(lastVisibleItemTranslation, locale) * 1000;

          setCountdownPeriodPerLocale((prev) => ({ ...prev, [locale]: estimatedTime_ms / 1000 }));

          if (waitTime < estimatedTime_ms) {
            const timeoutPeriod = estimatedTime_ms - waitTime + 50;
            setTimeout(() => recomputeVisibleTranslations(), timeoutPeriod);
            return;
          }
        }
      }

      if (requiresEstimation && timeline[index + 1]) {
        runAgain = true;
        const newIndex = index + 1;
        lastVisibleItemIndex_perLocale.current[locale] = newIndex;
        translationWaitStartTimestamps.current[newIndex] = {
          ...(translationWaitStartTimestamps.current?.[newIndex] ?? {}),
          [locale]: Date.now(),
        };
        setLastVisibleItemIndexForLocale((prev) => ({ ...prev, [locale]: newIndex }));
      }
    });

    if (runAgain) {
      recomputeVisibleTranslations();
    }
  }, [timeline]);

  useEffect(() => {
    if (timeline && timeline.length > lastVisibleItemIndex.current + 1) {
      recomputeVisibleItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recomputeVisibleItems]);

  useEffect(recomputeVisibleTranslations, [recomputeVisibleTranslations]);

  return {
    countdownPeriod,
    lastVisibleItemIndex: lastVisibleItemIndex_state,
    lastVisibleItemIndexForLocale,
    countdownPeriodPerLocale,
  };
};
