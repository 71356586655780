import dynamic from 'next/dynamic';

export const Particles = () => {
  const ParticlesClient = dynamic(
    () => import('@/components/widgets/backgrounds/ParticlesClient').then(({ Particles }) => Particles),
    { ssr: false },
  );

  return <ParticlesClient />;
};
