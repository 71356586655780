import { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import { Timeline } from '@vocalstack/js-sdk';

import { PolyglotPresenterTTS } from '@/components/pages/polyglot-presenter/PolyglotPresenterTTS';
import { useTranslations } from '@/context/TranslationsContext';
import { LocaleKey } from '@/constants/locales';

export const usePolyglotPresenterTtsPortal = (view: string) => {
  const { translationsWithoutDetected } = useTranslations();

  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const [ttsCurrentTime, setTtsCurrentTime] = useState<number | undefined>(undefined);
  const [ttsState, setTtsState] = useState<'paused' | 'playing'>('paused');
  const [currentSegment, setCurrentSegment] = useState<number | undefined>(undefined);

  useEffect(() => {
    const el = document.getElementById('tts-portal');
    el && setPortalElement(el);
  }, [view, translationsWithoutDetected]);

  const portalNode = <>{portalElement && createPortal(<PolyglotPresenterTTS />, portalElement)}</>;

  const isTimelineTranslationBeingRead = (timeline: Timeline, translation: LocaleKey, index: number) => {
    if (translationsWithoutDetected?.[0] !== translation) return false;
    const firstTranslation = timeline.findIndex(
      (item) => item.translations?.[translation] && item.translations?.[translation] !== '...',
    );
    const readIndex = Number(currentSegment) + firstTranslation;
    return index === readIndex;
  };

  return {
    portalNode,
    ttsCurrentTime,
    setTtsCurrentTime,
    ttsState,
    setTtsState,
    currentSegment,
    setCurrentSegment,
    isTimelineTranslationBeingRead,
  };
};
