import React, { useRef, useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { useIsIntersecting } from '@/hooks/useIsIntersecting';

const sample = [
  //0.03, 0.03,
  0.03, 0.04, 0.07, 0.23, 0.18, 0.5, 0.85, 1, 0.78, 0.7, 0.5, 0.3, 0.12, 0.07, 0.23, 0.5, 0.58, 0.4, 0.2, 0.13, 0.05,
  0.15, 0.2, 0.15, 0.12,
  //0.08, 0.05, 0.04,
  // 0.03, 0.03, 0.04, 0.03, 0.03,
];

const duration = 150;

export function PerlinSpectrum({ className, barCSS }: { className?: string; barCSS?: string }) {
  const [scales, setScales] = useState(() => sample.map(() => 0));
  const noiseRef = useRef<Perlin>();

  const parentRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useIsIntersecting(parentRef);
  const isIntersectingRef = useRef(isIntersecting);

  useEffect(() => {
    isIntersectingRef.current = isIntersecting;
  }, [isIntersecting]);

  useEffect(() => {
    noiseRef.current = new Perlin();

    function jump() {
      if (!isIntersectingRef.current) return;
      const newScales = sample.map((baseValue, i) => {
        const noiseVal = Number(noiseRef.current?.getValue(i * 0.1));
        let value = ((noiseVal + 1) / 2) * Math.random() * baseValue;
        value = value < 0.01 ? 0.01 : value;
        return value;
      });
      setScales(newScales);
    }

    const intervalId = setInterval(() => {
      requestAnimationFrame(jump);
    }, duration);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div ref={parentRef} className={twMerge('flex h-48 items-center justify-center', className)}>
      {scales.map((scale, i) => (
        <div
          key={i}
          className={twMerge(
            'mx-[2px] h-full w-[6px] shrink-0 bg-black !transition-transform !duration-150 ease-in-out will-change-transform transform dark:bg-white',
            barCSS,
          )}
          style={{ transform: `scale3d(1, ${scale}, 1)` }}
        />
      ))}
    </div>
  );
}

class Perlin {
  perm = (() => {
    const tmp = Array.from({ length: 256 }, () => Math.floor(Math.random() * 256));
    return tmp.concat(tmp);
  })();

  grad(i: number, x: number) {
    const h = i & 0xf;
    const grad = 1 + (h & 7);
    return (h & 8) !== 0 ? -grad * x : grad * x;
  }

  getValue(x: number) {
    const i0 = Math.floor(x);
    const i1 = i0 + 1;

    const x0 = x - i0;
    const x1 = x0 - 1;

    let t0 = 1 - x0 * x0;
    t0 *= t0;

    let t1 = 1 - x1 * x1;
    t1 *= t1;

    const n0 = t0 * t0 * this.grad(this.perm[i0 & 0xff], x0);
    const n1 = t1 * t1 * this.grad(this.perm[i1 & 0xff], x1);

    return 0.395 * (n0 + n1);
  }
}
