import { useState } from 'react';

export type PolyglotPresenterView = 'sentences' | 'timeline';

export const usePolyglotPresenterUI = () => {
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false);
  const [view, setView] = useState<PolyglotPresenterView>('sentences');
  const [fontSize, setFontSize] = useState<number>(20);
  const [showDetectedLanguage, setShowDetectedLanguage] = useState<boolean>(true);

  return {
    isMenuExpanded,
    setIsMenuExpanded,
    view,
    setView,
    fontSize,
    setFontSize,
    showDetectedLanguage,
    setShowDetectedLanguage,
  };
};
