'use client';

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CheckIcon,
  Cog6ToothIcon,
  LinkIcon,
  MicrophoneIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import Image from 'next/image';
import { PlayCircleIcon } from '@heroicons/react/24/solid';

import { PulseAnimation } from '../polyglot/PolyglotExample/PulseAnimation/PulseAnimation';

import { PerlinSpectrum } from './PerlinSpectrum';

import { useTranslation } from '@/hooks/useTranslation';
import { GhostAnchor, primaryColorsCSS } from '@/components/Fields/Buttons';
import { Heading } from '@/components/Fields/Heading';
import { DownIcon, Mp3Icon, ParagraphIcon, PolyglotIcon, VocalStackIcon, PhoneIcon } from '@/components/icons/icons';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';
import { AutoSnap } from '@/components/widgets/AutoSnap/AutoSnap';
import { TabContent, TabContentContainer } from '@/components/widgets/Tabs';
import { PricingButton } from '@/components/Pricing/PricingButton';
import { prices } from '@/constants/pricingData';
import { Translation } from '@/components/Translation';
import { Toggle } from '@/components/Fields/Toggle';
import { DotsBackground } from '@/components/widgets/DotsBackground/DotsBackground';
import { CountryFlag } from '@/components/widgets/CountryFlag/CountryFlag';
import { useIsIntersecting } from '@/hooks/useIsIntersecting';
import { DeviceFrame } from '@/components/widgets/DeviceFrame/DeviceFrame';

type FeatureType = 'file' | 'link' | 'microphone' | 'livestream';
type LiveType = 'Standard' | 'Polyglot';
type OutputType = 'Text' | 'Voice';
type Keyword = { action: () => void; value: string };

const buildTranscriptionUrl = (feature: FeatureType, liveType: LiveType) => {
  if (typeof window === 'undefined') return '';
  const url = new URL(window.location.origin + window.location.pathname);
  url.searchParams.set('transcription-type', feature);
  url.searchParams.set('live-type', liveType);
  return url.toString();
};

/*
  ai voice translator             KD: 10, SV: 800   - commercial, transactional (increasing trend)
  transcribe audio to text        KD: 18, SV: 14k   - commercial, transactional
  audio to text converter         KD: 34, SV: 6.8k  - commercial, transactional
  real time translator app        KD: 69, SV: 400   - commercial, transactional
  audio to text transcription     KD: 39, SV: 1.7k  - informational
  real time translation           KD: 27, SV: 450
  ai live translation             KD: 21, SV: 150
  ai voice transcription          KD: 23, SV: 150   - commercial, informative
  convert mp3 to text             KD: 12, SV: 400   - transactional
  live audio translation          KD: 37, SV: 200
  instant translation             KD: 37, SV: 200
*/

export const HomePageWhatIsVocalStack = ({ feature }: { feature?: FeatureType }) => {
  const [activeFeature, setActiveFeature] = useState<FeatureType>(feature ?? 'microphone');
  const [liveType, setLiveType] = useState<LiveType>('Polyglot');
  const [outputType, setOutputType] = useState<OutputType>('Voice');

  const description = useTranslation(
    ['what-is-vs', 'description'],
    'VocalStack is the best solution to transcribe audio to text with instant voice translation and live sharing capabilities! It is an audio to text converter app and an ai voice translator app powered by large AI models like OpenAI’s Whisper',
  );

  const keywords: Keyword[] = [
    {
      value: 'speech-to-speech translation',
      action: () => {
        setLiveType('Polyglot');
        setOutputType('Voice');
        setActiveFeature('microphone');
      },
    },
    {
      value: 'speech-to-text translation',
      action: () => {
        setLiveType('Polyglot');
        setOutputType('Text');
        setActiveFeature('microphone');
      },
    },
    {
      value: 'livestream real-time transcription',
      action: () => {
        setLiveType('Polyglot');
        setOutputType('Text');
        setActiveFeature('livestream');
      },
    },
    {
      value: 'livestream instant translation',
      action: () => {
        setLiveType('Polyglot');
        setOutputType('Voice');
        setActiveFeature('livestream');
      },
    },
    {
      value: 'mp3 to text converter',
      action: () => {
        setActiveFeature('file');
      },
    },
    {
      value: 'online audio transcriber',
      action: () => {
        setActiveFeature('link');
      },
    },
  ].map((keyword) => ({
    ...keyword,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    value: useTranslation(['what-is-vs', 'keywords', keyword.value], keyword.value),
  }));

  const features: Record<FeatureType, string[]> = {
    file: [
      useTranslation(
        ['what-is-vs', 'file', 'transcribe-audio'],
        'Transcribe audio to text directly from files on your device',
      ),
      useTranslation(
        ['what-is-vs', 'file', 'high-speed-transcription'],
        'High-speed transcription: Convert one hour of audio in about one minute',
      ),
      useTranslation(
        ['what-is-vs', 'file', 'incremental-loading'],
        'Incremental loading: Receive partial results while transcription is in progress',
      ),
      useTranslation(
        ['what-is-vs', 'file', 'supported-formats'],
        'Supported formats include: MP3, M4A, WAV, AAC, FLAC, OGG, WMA, AIFF',
      ),
    ],
    link: [
      useTranslation(['what-is-vs', 'link', 'transcribe-via-url'], 'Transcribe audio files to text via a URL'),
      useTranslation(
        ['what-is-vs', 'link', 'no-download'],
        'No need to download audio files to your device before transcribing',
      ),
      useTranslation(
        ['what-is-vs', 'link', 'auto-detect-formats'],
        'Automatically detect and process various audio formats from the provided URL',
      ),
      useTranslation(
        ['what-is-vs', 'link', 'same-benefits'],
        'Enjoy the same benefits and functionality as file transcriptions',
      ),
    ],
    microphone: [
      useTranslation(
        ['what-is-vs', 'microphone', 'real-time-transcription'],
        'Real-time audio to text transcription from your microphone',
      ),
      useTranslation(
        ['what-is-vs', 'microphone', 'vocalstack-dashboard'],
        'Use your VocalStack Dashboard to record from any device with a web browser',
      ),
      useTranslation(
        ['what-is-vs', 'microphone', 'low-latency'],
        'Low latency processing for instant transcription results',
      ),
      useTranslation(
        ['what-is-vs', 'microphone', 'translate-live'],
        'Translate live transcriptions into over 50 languages in real-time',
      ),
    ],
    livestream: [
      useTranslation(
        ['what-is-vs', 'livestream', 'transcribe-live-streams'],
        'Transcribe audio to text from live streams',
      ),
      useTranslation(['what-is-vs', 'livestream', 'supports-hls'], 'Supports HLS (HTTP Live Streaming) playlist files'),
      useTranslation(
        ['what-is-vs', 'livestream', 'same-benefits-microphone'],
        'Experience the same benefits and functionality as microphone transcriptions',
      ),
    ],
  };

  const polyglotFeatures = [
    useTranslation(
      ['what-is-vs', 'polyglot', 'unique-transcription-link'],
      'Create a unique transcription link to share with others',
    ),
    useTranslation(
      ['what-is-vs', 'polyglot', 'read-live'],
      'Your users can read the live transcriptions in their preferred language',
    ),
    useTranslation(
      ['what-is-vs', 'polyglot', 'multiple-languages'],
      'Supports multiple languages in a single transcription',
    ),
    useTranslation(['what-is-vs', 'polyglot', 'no-download'], 'No need to download or install any software'),
    useTranslation(
      ['what-is-vs', 'polyglot', 'tts'],
      'Your users can listen to the live transcriptions in their preferred language',
    ),
  ].filter((_, index) => (outputType === 'Voice' ? true : index < 4));

  const mounted = useRef(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const polyglot = liveType === 'Polyglot' && (activeFeature === 'microphone' || activeFeature === 'livestream');

  useEffect(() => {
    if (mounted.current) return;
    setTimeout(() => (mounted.current = true));
    const url = new URL(window.location.href);
    const urlFeature = url.searchParams.get('transcription-type');
    const urlLiveType = url.searchParams.get('live-type');
    if (urlFeature && Object.keys(features).includes(urlFeature)) {
      setActiveFeature(urlFeature as FeatureType);
    }
    if (urlLiveType && ['Standard', 'Polyglot'].includes(urlLiveType)) {
      setLiveType(urlLiveType as LiveType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mounted.current) return;
    const url = new URL(window.location.href);
    url.searchParams.set('transcription-type', activeFeature);
    window.history.replaceState({}, '', url.toString());
  }, [activeFeature]);

  useEffect(() => {
    if (!mounted.current) return;
    const url = new URL(window.location.href);
    url.searchParams.set('live-type', liveType);
    window.history.replaceState({}, '', url.toString());
  }, [liveType]);

  useEffect(() => {
    if (!mounted.current) return;
    setActiveFeature((feature) => {
      if (feature === 'file' || feature === 'link') {
        return 'microphone';
      }
      return feature;
    });
  }, [liveType]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            if (entry.boundingClientRect.top > 0) return;
            const url = new URL(window.location.href);
            url.searchParams.delete('transcription-type');
            url.searchParams.delete('live-type');
            url.hash = '';
            window.history.replaceState({}, '', url.toString());
          } else if (mounted.current) {
            const newUrl = buildTranscriptionUrl(activeFeature, liveType);
            const finalUrl = `${newUrl}#what-is-vocalstack`;
            window.history.replaceState({}, '', finalUrl);
          }
        });
      },
      { threshold: 0.5 },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [activeFeature, liveType]);

  return (
    <>
      <div className="bg-white pb-10 pt-20 dark:bg-background-800 sm:pb-20 sm:pt-28">
        <MaxWidthContainer className="flex flex-col gap-10 sm:gap-20">
          <Heading id="what-is-vocalstack" level={2} className="mx-auto justify-center">
            <Translation name="what-is-vs.title">What is VocalStack?</Translation>
          </Heading>

          <p className="text-balance relative z-50 text-center sm:text-lg sm:font-semibold">{description}</p>

          <div className="mx-auto flex flex-wrap items-center justify-center gap-3 py-1 lg:max-w-4xl lt-md:hidden">
            {keywords.map(({ action, value }, index) => (
              <button
                key={index}
                className="group cursor-pointer rounded-lg bg-primary-400/10 px-4 transition-all hover:bg-primary-400/20 dark:bg-primary-600/20 hover:dark:bg-primary-600/30"
                onClick={action}
              >
                <span className="opacity-60 transition-all group-hover:opacity-70">{value}</span>
              </button>
            ))}
          </div>

          <TabContentContainer
            activeTab={`${activeFeature}-${polyglot ? 1 : 0}-${outputType}`}
            className="!overflow-visible lg:mt-6"
          >
            <TabContent active>
              <div
                ref={componentRef}
                className="flex items-center gap-10 lg:gap-20 lt-md:mx-auto lt-md:max-w-md lt-md:flex-col"
              >
                <Headings
                  activeFeature={activeFeature}
                  setActiveFeature={setActiveFeature}
                  liveType={liveType}
                  setLiveType={setLiveType}
                  outputType={outputType}
                  setOutputType={setOutputType}
                />
                <div className="flex w-full flex-col">
                  <Animation feature={activeFeature} liveType={liveType} outputType={outputType} />

                  <Highlights
                    className={classNames({ hidden: activeFeature !== 'file' })}
                    features={features.file}
                    fixBg
                  />
                  <Highlights
                    className={classNames({ hidden: activeFeature !== 'link' })}
                    features={features.link}
                    fixBg
                  />
                  <Highlights
                    className={classNames({ hidden: activeFeature !== 'microphone' })}
                    features={features.microphone}
                    fixBg
                  />
                  <Highlights
                    className={classNames({ hidden: activeFeature !== 'livestream' })}
                    features={features.livestream}
                    fixBg
                  />

                  <div
                    className={classNames(
                      'flex items-center justify-center transition-all before:border-t-[1px] before:transition-all before:duration-300 after:border-t-[1px] after:transition-all after:duration-300',
                      'before:border-emerald-400 after:border-emerald-400',
                      {
                        'my-10 before:w-full after:w-full': polyglot,
                        'before:w-0 after:w-0': !polyglot,
                      },
                    )}
                  >
                    <span
                      className={classNames('relative px-4 text-xl font-black transition-all duration-300', {
                        'scale-0': !polyglot,
                      })}
                    >
                      <PolyglotIcon className="w-12 text-emerald-400" />
                    </span>
                  </div>
                  {polyglot && (
                    <div className="relative">
                      <div className="absolute h-[calc(100%+1.5rem)] w-[calc(100%+1.5rem)] rounded-lg bg-emerald-400/10 dark:bg-emerald-600/20 sm:left-[-1.5rem] sm:top-[-1.5rem] sm:h-[calc(100%+3rem)] sm:w-[calc(100%+3rem)] lt-sm:-m-3" />
                      <Highlights className="z-10" features={polyglotFeatures} />
                    </div>
                  )}
                  <Buttons feature={activeFeature} />
                </div>
              </div>
            </TabContent>
          </TabContentContainer>

          {/* <Heading id="what-is-vocalstack" level={2} className="mx-auto justify-center">
            <Translation name="what-is-vs.languages">What Languages does VocalStack support?</Translation>
          </Heading> */}
        </MaxWidthContainer>
      </div>
      <Video />
    </>
  );
};

const Headings = ({
  activeFeature,
  setActiveFeature,
  liveType,
  setLiveType,
  outputType,
  setOutputType,
}: {
  activeFeature: FeatureType;
  setActiveFeature: Dispatch<SetStateAction<FeatureType>>;
  liveType: LiveType;
  setLiveType: Dispatch<SetStateAction<LiveType>>;
  outputType: OutputType;
  setOutputType: Dispatch<SetStateAction<OutputType>>;
}) => {
  const HeadingTab = ({
    children,
    active,
    feature,
  }: {
    children: React.ReactNode;
    active?: boolean;
    feature: FeatureType;
  }) => (
    <a
      href={buildTranscriptionUrl(feature, liveType)}
      onClick={(e) => {
        e.preventDefault();
        setActiveFeature(feature);
      }}
      suppressHydrationWarning
    >
      <Heading
        level={4}
        className={classNames(
          'cursor-pointer justify-center py-4 !text-base font-light transition-all md:p-4 lt-sm:!text-sm',
          {
            'bg-emerald-300 dark:bg-emerald-600': active,
            'hover:bg-emerald-300 hover:dark:bg-emerald-600': !active,
            'bg-emerald-400/20 dark:bg-emerald-600/20': !active,
          },
        )}
        onClick={() => setActiveFeature(feature)}
      >
        {children}
      </Heading>
    </a>
  );
  const sectionCSS = 'flex flex-col rounded-lg bg-primary-400/10 p-3 md:p-5 dark:bg-primary-600/20 lt-md:w-1/2 gap-0.5';
  const inactiveToggleCSS = '[&_.toggle-slider]:dark:!bg-gray-900/50 opacity-60';
  const activeToggleCSS = '[&_.toggle-slider]:!bg-emerald-300 [&_.toggle-slider]:dark:!bg-emerald-600';
  const isLive = activeFeature === 'microphone' || activeFeature === 'livestream';
  const toggleLiveType = (
    <span onClick={() => !isLive && setActiveFeature('microphone')}>
      <Toggle
        className={classNames('mx-auto whitespace-nowrap md:mt-1', {
          [inactiveToggleCSS]: !isLive,
          [activeToggleCSS]: isLive,
        })}
        value1="Standard"
        value2="Polyglot"
        icons={[VocalStackIcon, PolyglotIcon]}
        activeValue={liveType}
        onChange={(val) => {
          setLiveType(val as LiveType);
          val !== 'Polyglot' && setOutputType('Text');
        }}
        disabled={!isLive}
      />
    </span>
  );
  const toggleOutputType = (
    <span
      onClick={() => liveType !== 'Polyglot' && setLiveType('Polyglot')}
      className={classNames('max-h-[100px] overflow-clip transition-all', {
        '-m-2 opacity-100 md:!max-h-0 lt-md:hidden': liveType === 'Standard',
      })}
    >
      <Toggle
        className={classNames('mx-auto whitespace-nowrap md:mt-1', {
          [inactiveToggleCSS]: !isLive,
          [activeToggleCSS]: isLive,
        })}
        value1="Text"
        value2="Voice"
        icons={[ParagraphIcon, ChatBubbleOvalLeftEllipsisIcon]}
        activeValue={outputType}
        onChange={(val) => setOutputType(val as OutputType)}
      />
    </span>
  );
  return (
    <>
      <div className="flex select-none gap-2 md:mb-auto md:flex-col md:gap-4">
        <div className={sectionCSS}>
          <h3 className="mb-4 justify-center text-center text-xs font-bold lt-md:mb-auto">
            <Translation name="what-is-vs.pre-recorded-title">Pre-recorded Speech Transcription</Translation>
          </h3>
          <HeadingTab feature="file" active={activeFeature === 'file'}>
            <Translation name="what-is-vs.file-to-text" nonDefaultLocaleText="Transcribe Audio Files to Text">
              Audio Files to Text
            </Translation>
          </HeadingTab>
          <HeadingTab feature="link" active={activeFeature === 'link'}>
            <Translation name="what-is-vs.link-to-text" nonDefaultLocaleText="Transcribe Audio Links to Text">
              Audio Link to Text
            </Translation>
          </HeadingTab>
        </div>
        <div className={sectionCSS}>
          <h3 className="mb-4 justify-center text-center text-xs font-bold lt-md:mb-auto">
            <Translation name="what-is-vs.live-title">Live Speech Transcription</Translation>
          </h3>
          <span className="mb-4 flex flex-col items-center justify-center gap-4 lt-md:hidden">
            {toggleLiveType}
            {toggleOutputType}
          </span>
          <HeadingTab feature="microphone" active={activeFeature === 'microphone'}>
            <Translation
              name="what-is-vs.mic-to-text"
              nonDefaultLocaleText="Transcribe from Microphone to Text"
              className={classNames({ hidden: outputType !== 'Text' })}
            >
              Microphone to Text
            </Translation>
            <Translation
              name="what-is-vs.mic-to-voice"
              nonDefaultLocaleText="Transcribe from Microphone to Voice"
              className={classNames({ hidden: outputType !== 'Voice' })}
            >
              Microphone to Voice
            </Translation>
          </HeadingTab>
          <HeadingTab feature="livestream" active={activeFeature === 'livestream'}>
            <Translation
              name="what-is-vs.livestream-to-text"
              nonDefaultLocaleText="Transcribe from LiveStream to Text"
              className={classNames({ hidden: outputType !== 'Text' })}
            >
              LiveStream to Text
            </Translation>
            <Translation
              name="what-is-vs.livestream-to-voice"
              nonDefaultLocaleText="Transcribe from LiveStream to Speech"
              className={classNames({ hidden: outputType !== 'Voice' })}
            >
              LiveStream to Voice
            </Translation>
          </HeadingTab>
        </div>
      </div>
      <span className="-mt-5 flex flex-wrap items-center justify-center gap-4 md:hidden">
        {toggleLiveType}
        {toggleOutputType}
      </span>
    </>
  );
};

const Animation = ({
  feature,
  liveType,
  outputType,
}: {
  feature: FeatureType;
  liveType: LiveType;
  outputType: OutputType;
}) => {
  const iconCSS =
    'w-10 shrink-0 animate-ping [animation-direction:reverse] [animation-duration:200ms] dark:text-emerald-500 text-emerald-400 md:w-20 [animation-iteration-count:1]';
  const polyglot = liveType === 'Polyglot' && (feature === 'microphone' || feature === 'livestream');
  const Icon = polyglot ? PolyglotIcon : VocalStackIcon;
  const parentRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useIsIntersecting(parentRef, {});
  return (
    <div ref={parentRef} className="flex w-full items-center gap-1 lg:gap-4 lt-md:-translate-y-5">
      <div>
        <Mp3Icon className={iconCSS} style={{ display: feature === 'file' ? '' : 'none' }} />
        <LinkIcon className={iconCSS} style={{ display: feature === 'link' ? '' : 'none' }} />
        <MicrophoneIcon className={iconCSS} style={{ display: feature === 'microphone' ? '' : 'none' }} />
        <VideoCameraIcon className={iconCSS} style={{ display: feature === 'livestream' ? '' : 'none' }} />
      </div>
      <PulseAnimation className="w-full opacity-20 dark:opacity-50" />
      <span className="relative">
        <Icon className="absolute inset-0 z-20 m-auto w-4 text-emerald-400 translate-y-[1px] dark:text-emerald-500 md:w-8" />
        <span className="absolute inset-0 z-10 m-auto h-6 w-6 rounded-full bg-white dark:bg-background-800 md:h-12 md:w-12" />
        <Cog6ToothIcon
          className="w-14 animate-spin text-emerald-400 [animation-duration:6s] dark:text-emerald-500 md:w-28"
          style={{ animationPlayState: isIntersecting ? 'running' : 'paused' }}
        />
      </span>
      <PulseAnimation className="w-full opacity-20 -translate-x-2" />
      <div className="relative flex aspect-square w-48 shrink-0 items-center justify-center md:w-52 lg:w-60 xl:w-80">
        {!polyglot && <TextAnimation isIntersecting={isIntersecting} />}
        {polyglot && <PolyglotAnimation outputType={outputType} isIntersecting={isIntersecting} />}
      </div>
    </div>
  );
};

const PolyglotAnimation = ({ outputType, isIntersecting }: { outputType: OutputType; isIntersecting: boolean }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setTimeout(() => setMounted(true));
    return () => setMounted(false);
  }, [mounted]);

  const inside = (
    <>
      <PhoneIcon className="absolute w-full stroke-current stroke-[0.6px] text-emerald-400 dark:text-emerald-500" />
      {outputType === 'Voice' && (
        <PerlinSpectrum
          className="absolute -bottom-14 w-full scale-[0.4] lg:-bottom-12 lg:scale-[0.5] xl:-bottom-10 lt-md:scale-y-[0.25]"
          barCSS="bg-emerald-400 dark:bg-emerald-500"
        />
      )}
      <TextAnimation
        isIntersecting={isIntersecting}
        className={classNames('relative bottom-0 !max-h-full transition-all scale-[0.4]', {
          '!bottom-4 lg:!bottom-8': outputType === 'Voice',
        })}
      />
    </>
  );
  return (
    <div
      className={classNames('translate-all h-full w-full', {
        '-translate-x-1/3': mounted,
      })}
      style={{ transformStyle: 'preserve-3d', perspective: '1000px' }}
    >
      <div
        className={classNames('h-full w-full transition-all', {
          'rotate-y-0': !mounted,
          'rotate-y-[-30deg]': mounted,
        })}
      >
        {inside}
        <CountryFlag
          className={classNames('!absolute bottom-[27%] left-0 right-0 mx-auto !h-7 !w-7 opacity-60 transition-all', {
            'scale-0': outputType !== 'Voice',
          })}
          rounded
          pulsate
          code="gb"
        />
      </div>
      <div
        className={classNames('absolute top-0 h-full w-full transition-all', {
          'rotate-y-0': !mounted,
          '-translate-y-1/2 translate-x-1/2 rotate-y-[-30deg] scale-75 lt-md:-translate-y-1/3 lt-md:scale-50': mounted,
        })}
      >
        {inside}
        <CountryFlag
          className={classNames('!absolute bottom-[27%] left-0 right-0 mx-auto !h-7 !w-7 transition-all', {
            'scale-0': outputType !== 'Voice',
          })}
          rounded
          pulsate
          code="es"
        />
      </div>
      <div
        className={classNames('absolute top-0 h-full w-full transition-all', {
          'rotate-y-0': !mounted,
          'translate-x-1/2 translate-y-1/2 rotate-y-[-30deg] scale-75 lt-md:translate-y-1/3 lt-md:scale-50': mounted,
        })}
      >
        {inside}
        <CountryFlag
          className={classNames('!absolute bottom-[27%] left-0 right-0 mx-auto !h-7 !w-7 transition-all', {
            'scale-0': outputType !== 'Voice',
          })}
          rounded
          pulsate
          code="gr"
        />
      </div>
    </div>
  );
};

const TextAnimation = ({ className, isIntersecting }: { className?: string; isIntersecting: boolean }) => {
  const duration = 5000;
  const [items, setItems] = useState([{ width: 100 }, { width: 75 }, { width: 90 }, { width: 60 }]);
  const itemsRef = useRef(items);
  const isIntersectingRef = useRef(isIntersecting);

  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  useEffect(() => {
    isIntersectingRef.current = isIntersecting;
  }, [isIntersecting]);

  const generateNewWidth = () => {
    const min = 50;
    const max = 100;
    const lastFinalWidth = itemsRef.current[itemsRef.current.length - 1].width;
    const lowerLimit = Math.floor(lastFinalWidth * 0.8);
    const upperLimit = Math.ceil(lastFinalWidth * 1.2);
    const possibleRanges = [];
    if (lowerLimit >= min) {
      possibleRanges.push([min, lowerLimit]);
    }
    if (upperLimit <= max) {
      possibleRanges.push([upperLimit, max]);
    }

    if (possibleRanges.length === 0) {
      return Math.floor(Math.random() * 51) + 50;
    }
    const [rangeMin, rangeMax] = possibleRanges[Math.floor(Math.random() * possibleRanges.length)];
    return Math.floor(Math.random() * (rangeMax - rangeMin + 1)) + rangeMin;
  };

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];

    const runAnimation = () => {
      if (document.visibilityState !== 'visible') return;
      if (!isIntersectingRef.current) return;
      const width = generateNewWidth();
      setItems((prev) => [...prev, { width: 0.2 * width }]);
      timeouts.push(
        setTimeout(() => {
          setItems((prev) => {
            const newItems = [...prev];
            const lastIndex = newItems.length - 1;
            newItems[lastIndex] = { ...newItems[lastIndex], width: 0.5 * width };
            return newItems;
          });
        }, duration / 4),
      );

      timeouts.push(
        setTimeout(() => {
          setItems((prev) => {
            const newItems = [...prev];
            const lastIndex = newItems.length - 1;
            newItems[lastIndex] = { ...newItems[lastIndex], width: 0.75 * width };
            return newItems;
          });
        }, duration / 2),
      );

      timeouts.push(
        setTimeout(
          () => {
            setItems((prev) => {
              const newItems = [...prev];
              const lastIndex = newItems.length - 1;
              newItems[lastIndex] = { ...newItems[lastIndex], width: 0.9 * width };
              return newItems;
            });
          },
          (duration / 4) * 3,
        ),
      );

      timeouts.push(
        setTimeout(() => {
          setItems((prev) => {
            const newItems = [...prev];
            const lastIndex = newItems.length - 1;
            newItems[lastIndex] = { ...newItems[lastIndex], width };
            return newItems;
          });
        }, duration - 100),
      );
    };

    runAnimation();
    const intervalId = setInterval(runAnimation, duration);

    return () => {
      clearInterval(intervalId);
      timeouts.forEach(clearTimeout);
    };
  }, []); // run once

  return (
    <AutoSnap
      className={classNames(
        'flex h-full max-h-24 w-full flex-col justify-center gap-4 !overflow-hidden md:max-h-32',
        className,
      )}
    >
      {items.map(({ width }, index) => (
        <span
          key={index}
          className={classNames(
            'h-3 shrink-0 rounded-xl bg-emerald-400 transition-all duration-500 dark:bg-emerald-500 md:h-4',
            {
              'animate-slide-up opacity-40': index === items.length - 1,
            },
          )}
          style={{ width: `${width}%` }}
        />
      ))}
    </AutoSnap>
  );
};

const Highlights = ({ className, features, fixBg }: { className?: string; features: string[]; fixBg?: boolean }) => (
  <ul
    className={classNames(
      'text-balance flex flex-col justify-center gap-4 font-light sm:max-w-lg sm:gap-6 lg:max-w-none lt-sm:text-sm lt-lg:mx-auto',
      className,
    )}
  >
    {features.map((feature, index) => (
      <li
        key={feature}
        className={classNames(
          'mr-auto flex animate-slide-down items-center gap-2 rounded-lg pr-3 font-medium tracking-wide',
          {
            '[&_li]:bg-white/80 [&_li]:dark:bg-background-800/80': fixBg,
          },
        )}
        style={{ animationDelay: `${index * 0.1}s` }}
      >
        <CheckIcon className="w-6 shrink-0 text-emerald-400" />
        {feature}
      </li>
    ))}
  </ul>
);

const Buttons = ({ feature }: { feature: FeatureType }) => {
  const readMoreLinks: Partial<Record<FeatureType, string>> = {
    file: '/articles/fundamentals/understanding-online-transcription-tools#how-vocalstack-works',
    link: '/articles/fundamentals/understanding-online-transcription-tools#how-vocalstack-works',
    // microphone: '/articles/tutorial/live-transcriptions-with-translations-shared-via-a-public-url',
    // livestream: '/articles/tutorial/live-transcriptions-with-translations-shared-via-a-public-url',
  };

  // const apiLinks: Record<FeatureType, string> = {
  //   file: '/documentation/transcribe-audio-from-url-to-text',
  //   link: '/documentation/transcribe-audio-from-url-to-text',
  //   microphone: '/documentation/transcribe-from-a-microphone-or-live-stream',
  //   livestream: '/documentation/transcribe-from-a-microphone-or-live-stream',
  // };
  return (
    <div className="mt-20 flex gap-10 lg:justify-between lt-lg:flex-col">
      <div className="flex flex-col gap-10">
        <Translation name="try.body" className="text-balance font-light opacity-80 lt-sm:text-center">
          Create a free account to get instant access to the VocalStack Dashboard. It only takes a few seconds to get
          started.
        </Translation>
        <PricingButton
          plan={prices.hobby}
          location="table"
          className={classNames('mr-auto empty:hidden lt-sm:mx-auto', primaryColorsCSS)}
        >
          <Translation name="button.get-started" nonDefaultLocaleText="Start Now" className="empty:hidden">
            Get Started
          </Translation>
        </PricingButton>
      </div>

      <div className="mt-auto flex gap-6 whitespace-nowrap empty:hidden">
        <GhostAnchor
          href={readMoreLinks[feature]}
          className={classNames('transition-all', { 'scale-0 lt-lg:hidden': !readMoreLinks[feature] })}
        >
          <Translation name="button.learn-more">Learn More</Translation>
        </GhostAnchor>
      </div>
    </div>
  );
};

const Video = () => {
  const [ready, setReady] = useState(false);

  return (
    <div className="relative flex flex-col gap-2 bg-primary-100 dark:bg-background-700/20">
      <MaxWidthContainer className="group relative h-[250px] cursor-pointer sm:h-[400px] md:h-[500px] lg:h-[650px] xl:h-[685px] lt-lg:!p-0">
        {!ready && (
          <div className="absolute inset-0" onClick={() => setReady(true)}>
            <h2 className="text-balance absolute right-0 top-2 z-10 w-full bg-gradient-to-r from-sky-500 to-emerald-500 bg-clip-text text-center text-xl font-extrabold text-transparent dark:from-sky-400 dark:to-emerald-500 md:mt-10 md:text-4xl lg:text-6xl">
              <Translation name="what-is-vs.see-in-action">See VocalStack in Action</Translation>
            </h2>
            <Image
              src="/caleb.webp"
              width={280}
              height={280}
              className="absolute bottom-0 left-2 top-0 z-10 my-auto aspect-square !w-[18%] rounded-full border-2 border-sky-400 dark:border-sky-600 sm:border-4"
              alt="Transcribing Speech to Text with VocalStack"
            />
            <div className="absolute inset-0 z-10 m-auto flex items-center justify-center scale-[20%] sm:scale-[35%] md:scale-[40%] lg:translate-y-4 lg:scale-[55%]">
              <DeviceFrame device="laptop" glow>
                <Image
                  src="/video-browser.webp?id=1"
                  className="absolute h-full w-full object-cover"
                  alt="Transcribing Speech to Text with VocalStack"
                  fill
                />
              </DeviceFrame>
            </div>
            <PlayCircleIcon className="absolute inset-0 z-20 m-auto w-16 text-primary-500 opacity-50 transition-all group-hover:opacity-90 group-hover:scale-125 dark:text-white sm:w-24" />
          </div>
        )}
        {ready && (
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/WAVG5XEnc68?autoplay=1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Transcribing Speech to Text with VocalStack"
          ></iframe>
        )}
      </MaxWidthContainer>
      <DotsBackground
        className={classNames(
          'absolute bottom-0 left-0 right-0 z-10 mx-auto aspect-square w-20 cursor-pointer overflow-clip rounded-full transition-all translate-y-[50%] hover:shadow-xl lg:w-28 lg:p-5',
          { 'scale-0': ready },
        )}
        darkBg="#143452"
        lightBg="white"
        darkDot="rgba(255, 255, 255, 0.35)"
      >
        <a href="#try-it-out" aria-hidden>
          <DownIcon className="w-inherit absolute inset-0 z-10 mx-auto fill-emerald-400 p-4 lg:p-5" />
        </a>
      </DotsBackground>
    </div>
  );
};
