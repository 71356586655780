/* eslint-disable react-hooks/rules-of-hooks */

// 🚨🚨🚨🚨🚨🚨 TODO: FIX THE ESLINT ERRORS 🚨🚨🚨🚨🚨

'use client';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { CountryFlag } from '@/components/widgets/CountryFlag/CountryFlag';
import { locales } from '@/constants/locales';
import { useIsIntersecting } from '@/hooks/useIsIntersecting';
import { AnimatedGlobeProps } from './AnimatedGlobe';

export const AnimatedGlobe: React.FC<AnimatedGlobeProps> = ({ className, grow }) => {
  const viewboxSize = 800;
  const radius = 250;
  const numLatitudes = 16; //23;
  const numLongitudes = 15; //22;
  const numBackgroundCircles = 80;
  const maxRadiusSize = 30;
  const colorRGB: RGB = { r: 124, g: 128, b: 220 };
  const FPS = 20;
  const frameInterval = 1000 / FPS;

  const globeRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useIsIntersecting(globeRef);
  const [hasIntersected, setHasIntersected] = useState<boolean>(false);

  const [backgroundCircles, setBackgroundCircles] = useState<BackgroundCircle[]>(() => {
    const circles: BackgroundCircle[] = [];
    for (let i = 0; i < numBackgroundCircles; i++) {
      circles.push({
        cx: Math.random() * viewboxSize - 100,
        cy: Math.random() * viewboxSize,
        r: Math.random() * maxRadiusSize + 5,
        dx: (Math.random() - 0.5) * 0.5,
        dy: (Math.random() - 0.5) * 0.5,
      });
    }
    return circles;
  });

  const [nodes, setNodes] = useState<Node[]>(() => {
    const initialNodes: Node[] = [];
    for (let lat = 0; lat <= numLatitudes; lat++) {
      const theta = (lat / numLatitudes) * Math.PI;
      const sinTheta = Math.sin(theta);
      const cosTheta = Math.cos(theta);

      for (let lon = 0; lon < numLongitudes; lon++) {
        const phi = (lon / numLongitudes) * 2 * Math.PI;
        const sinPhi = Math.sin(phi);
        const cosPhi = Math.cos(phi);

        initialNodes.push({
          baseTheta: theta,
          sinTheta,
          cosTheta,
          sinPhi,
          cosPhi,
          phi,
          phase: Math.random() * Math.PI * 2,
          speed: 0.5 + Math.random() * 0.5,
          x: 0,
          y: 0,
          z: 0,
        });
      }
    }
    return initialNodes;
  });

  const [rotationAngle, setRotationAngle] = useState<number>(0);
  const animationFrameId = useRef<number>(0);

  const [maxBackgroundCircles, setMaxBackgroundCircles] = useState<number>(0);

  const lines: [number, number][] = [];
  for (let lat = 0; lat <= numLatitudes; lat++) {
    for (let lon = 0; lon < numLongitudes; lon++) {
      const current = lat * numLongitudes + lon;
      const nextLon = lat * numLongitudes + ((lon + 1) % numLongitudes);
      const prevLon = lat * numLongitudes + ((lon - 1 + numLongitudes) % numLongitudes);

      lines.push([current, nextLon]);
      lines.push([current, prevLon]);

      if (lat < numLatitudes) {
        lines.push([current, (lat + 1) * numLongitudes + lon]);
      }
      if (lat > 0) {
        lines.push([current, (lat - 1) * numLongitudes + lon]);
      }
    }
  }

  let lastFrameTime = performance.now();

  const updateNodes = (time: number): void => {
    const oscillationFactor = 0.08;
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        const oscillation = Math.sin(node.phase + time * node.speed) * oscillationFactor;
        const adjustedTheta = node.baseTheta + oscillation;
        const sinAdjustedTheta = Math.sin(adjustedTheta);
        const cosAdjustedTheta = Math.cos(adjustedTheta);

        return {
          ...node,
          x: radius * sinAdjustedTheta * node.cosPhi,
          y: radius * cosAdjustedTheta,
          z: radius * sinAdjustedTheta * node.sinPhi,
        };
      }),
    );
  };

  const updateBackgroundCircles = (): void => {
    setBackgroundCircles((prevCircles) =>
      prevCircles.map((circle) => {
        let { cx, cy, dx, dy } = circle;
        const { r } = circle;
        cx += dx;
        cy += dy;

        if (cx - r < 0 || cx + r > viewboxSize) {
          dx *= -1;
        }
        if (cy - r < 0 || cy + r > viewboxSize) {
          dy *= -1;
        }

        return { cx, cy, dx, dy, r };
      }),
    );
  };

  const animate = (timestamp: number): void => {
    const delta = timestamp - lastFrameTime;
    if (delta >= frameInterval) {
      lastFrameTime = timestamp;
      const time = timestamp / 1000;
      updateNodes(time);
      updateBackgroundCircles();
      setRotationAngle((prev) => prev - 0.005);
    }
    animationFrameId.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (!grow) return;
    const interval = setInterval(() => {
      setMaxBackgroundCircles((prev) => prev + 1);
      if (maxBackgroundCircles >= numBackgroundCircles) {
        clearInterval(interval);
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (!isIntersecting) {
      cancelAnimationFrame(animationFrameId.current);
    } else {
      setHasIntersected(true);
      animationFrameId.current = requestAnimationFrame(animate);
    }
  }, [isIntersecting]);

  const project3D = (x: number, y: number, z: number): Point2D => {
    const scale = 1 - z / (2 * radius);
    return { x: x * scale, y: y * scale };
  };

  const projectedNodes: ProjectedNode[] = nodes.map(({ x, y, z }) => {
    //  // Convert rotationAngle from degrees to radians for transformation
    //  const angleRad = (rotationAngle * Math.PI) / 180;
    //  const rotatedX = x * Math.cos(angleRad) - z * Math.sin(angleRad);
    //  const rotatedZ = x * Math.sin(angleRad) + z * Math.cos(angleRad);
    const rotatedX = x * Math.cos(rotationAngle) - z * Math.sin(rotationAngle);
    const rotatedZ = x * Math.sin(rotationAngle) + z * Math.cos(rotationAngle);
    const projected = project3D(rotatedX, y, rotatedZ);
    const depth = (rotatedZ + radius) / (2 * radius); // Normalize depth between 0 and 1

    return {
      x: projected.x + 500, // Center X (viewBox width / 2)
      y: projected.y + 200, // Center Y (viewBox height / 2)
      depth,
    };
  });

  const color1: RGB = { r: 76, g: 81, b: 147 };
  const color2: RGB = { r: 195, g: 197, b: 236 };

  return (
    <div
      ref={globeRef}
      className={classNames('absolute inset-0 overflow-clip xl:left-[20%] lt-sm:bottom-[10%]', className)}
      suppressHydrationWarning
    >
      {hasIntersected && (
        <>
          {backgroundCircles.slice(0, locales.length + 5).map((circle, index) => (
            <span
              key={`bg-circle-${index}`}
              className="absolute left-0 top-0 rounded-full transition-transform duration-1000"
              style={{
                transform:
                  grow && index >= maxBackgroundCircles
                    ? `translate(${circle.cx}px,${circle.cy}px) scale(0)`
                    : `translate(${circle.cx}px,${circle.cy}px) scale(1)`,
                width: `${circle.r}px`,
                height: `${circle.r}px`,
              }}
              suppressHydrationWarning
            >
              <CountryFlag
                code={index < locales.length ? locales[index]?.country : 'GB'}
                className={classNames('!absolute !top-0 !h-full !w-full bg-cover opacity-10 dark:opacity-10', {
                  'will-change-transform': isIntersecting,
                })}
                rounded
              />
            </span>
          ))}

          {/* Globe container */}
          <svg
            className={classNames('h-full w-full', {
              '!opacity-0': grow && maxBackgroundCircles <= 5,
              'transition-all duration-1000': grow && maxBackgroundCircles <= 10,
            })}
            viewBox="0 0 1000 400"
            preserveAspectRatio="xMidYMid meet"
          >
            <g id="globe" transform={`translate(200, 100) rotate(${rotationAngle}, 500, 200)`}>
              {/* Countries Circles */}
              <g id="background-circles" className="group">
                {backgroundCircles.map((circle, index) => (
                  <circle
                    key={`bg-circle-${index}`}
                    cx={circle.cx}
                    cy={circle.cy}
                    r={grow && index >= maxBackgroundCircles ? 0 : circle.r}
                    fill={`rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b})`}
                    strokeOpacity={grow && index >= maxBackgroundCircles ? 0 : 0.2}
                    fillOpacity={grow && index >= maxBackgroundCircles ? 0 : 0.2}
                    suppressHydrationWarning
                    className="bg-cover opacity-30 transition-all duration-1000 dark:opacity-20"
                    // style={{
                    //   willChange: 'transform',
                    // }}
                  />
                ))}
              </g>

              {/* Globe Lines */}
              <g id="globe-lines" className="group opacity-50 translate-x-1/4 dark:opacity-100 lt-sm:hidden">
                {lines.map(([startIdx, endIdx], index) => {
                  const start = projectedNodes[startIdx];
                  const end = projectedNodes[endIdx];
                  const avgDepth = (start.depth + end.depth) / 2;
                  const opacity = 1 - avgDepth / 1.2;
                  const maxStrokeWidth = 0.15;
                  const minStrokeWidth = 0.1;
                  const strokeWidth = minStrokeWidth + (maxStrokeWidth - minStrokeWidth) * (1 - avgDepth);

                  return (
                    <path
                      key={`line-${index}`}
                      d={`M ${start.x} ${start.y} L ${end.x} ${end.y}`}
                      stroke={`rgba(76, 81, 147, ${opacity})`}
                      strokeWidth={strokeWidth}
                      fill="none"
                    />
                  );
                })}
              </g>

              {/* Globe Nodes */}
              <g id="globe-nodes" className="group opacity-50 translate-x-1/4 dark:opacity-100 lt-sm:hidden">
                {projectedNodes.map(({ x, y, depth }, index) => {
                  const opacity = 1 - depth / 1.2;
                  const r = Math.floor(color1.r * (1 - depth) + color2.r * depth);
                  const g = Math.floor(color1.g * (1 - depth) + color2.g * depth);
                  const b = Math.floor(color1.b * (1 - depth) + color2.b * depth);
                  const color = `rgb(${r}, ${g}, ${b})`;
                  const maxRadius = 2;
                  const minRadius = 1;
                  const nodeRadius = minRadius + (maxRadius - minRadius) * (1 - depth);

                  return (
                    <circle
                      key={`node-${index}`}
                      cx={x}
                      cy={y}
                      r={nodeRadius}
                      stroke={color}
                      fill={color}
                      strokeOpacity={opacity}
                      fillOpacity={opacity}
                    />
                  );
                })}
              </g>
            </g>
          </svg>
        </>
      )}
    </div>
  );
};

// Type Definitions
type Point2D = {
  x: number;
  y: number;
};

type Point3D = Point2D & {
  z: number;
};

type Node = {
  baseTheta: number;
  sinTheta: number;
  cosTheta: number;
  sinPhi: number;
  cosPhi: number;
  phi: number;
  phase: number;
  speed: number;
} & Point3D;

type ProjectedNode = Point2D & {
  depth: number;
};

type BackgroundCircle = {
  cx: number;
  cy: number;
  r: number;
  dx: number;
  dy: number;
};

type RGB = {
  r: number;
  g: number;
  b: number;
};
