import { useEffect, useRef, useState } from 'react';

import { useWakeLock } from 'react-screen-wake-lock';
import {
  Polyglot,
  PolyglotPresenterConnection,
  PolyglotPresenterSession,
  PolyglotPresenterTranscription,
  Timeline,
} from '@vocalstack/js-sdk';

import { useGlobal } from '@/context/GlobalContext';

export const usePolyglotPresenterSession = (apiKey: string, link: string, password?: string) => {
  const { isSupported, released, request } = useWakeLock({
    onRequest: () => console.log('Screen Wake Lock: requested!'),
    onError: () => console.log('An error happened 💥'),
    onRelease: () => console.log('Screen Wake Lock: released!'),
  });

  const initialized = useRef(false);
  const connection = useRef<PolyglotPresenterConnection | null>(null);
  const [needsPassword, setNeedsPassword] = useState<undefined | boolean>(undefined);
  const [timeline, setTimeline] = useState<Timeline | undefined>();
  const [session, setSession] = useState<PolyglotPresenterSession>();
  const [activeRequest, setActiveRequest] = useState<PolyglotPresenterTranscription | undefined>();
  const { apiServer } = useGlobal();

  useEffect(() => {
    isSupported && !released && void request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiKey === 'demo') {
      setSession({
        name: 'Demo Session',
        id: 'demo',
        requests: [],
        fullUrl: 'https://www.vocalstack.com',
        is_public: true,
        link: 'demo',
      });
      initialized.current = true;
      return;
    }
    if (initialized.current) {
      return;
    }
    void (async () => {
      initialized.current = true;
      const sdk = new Polyglot({ apiKey, apiServer });
      connection.current = await sdk.getLiveSessionStream({ link, password });
      connection.current.onData((response) => {
        const { error, status, http_code, data } = response;

        if (error === 'Invalid API Key') {
          setTimeout(() => window.location.reload(), 2000);
          return;
        }

        if (typeof http_code === 'number' && http_code === 401) {
          setNeedsPassword(true);
          return;
        }
        if (status === 'waiting') {
          setNeedsPassword(false);
        }

        // session gets sent once at the beginning
        if (data?.session) {
          setSession(data.session);
          connection.current?.onClose(() => {
            window.location.reload();
          });
        }

        // transcription gets sent multiple times as it updates
        if (data?.activeTranscription) {
          setTimeline(data.activeTranscription.timeline);
          setActiveRequest(data.activeTranscription);
        }
      });
    })();
    return () => {
      connection.current?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return {
    link,
    timeline,
    session,
    activeRequest,
    needsPassword,
    badPassword: !!password,
    setTimeline, // polyglot example uses this
    setSession, // polyglot example uses this
  };
};
