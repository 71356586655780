import classNames from 'classnames';

import './pulse.scss';
import { useRef } from 'react';
import { useIsIntersecting } from '@/hooks/useIsIntersecting';

type PulseAnimationProps = {
  className?: string;
};

export const PulseAnimation = (props: PulseAnimationProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useIsIntersecting(parentRef);
  return (
    <div ref={parentRef} className={classNames('flex items-center', props.className)}>
      <div className="connection">
        <span className={classNames('point', isIntersecting && 'active')}></span>
        <span className={classNames('point', isIntersecting && 'active')}></span>
        <span className={classNames('point', isIntersecting && 'active')}></span>
        <span className={classNames('point', isIntersecting && 'active')}></span>
        <span className={classNames('point lt-md:!hidden lt-lg:!hidden', isIntersecting && 'active')}></span>
        <span className={classNames('point lt-md:!hidden lt-lg:!hidden', isIntersecting && 'active')}></span>
      </div>
    </div>
  );
};
