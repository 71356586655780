import { useEffect, useState, useRef } from 'react';

import classNames from 'classnames';
import Hls from 'hls.js';

import { usePolyglotPresenter } from '@/context/PolyglotPresenterContext';
import { useTranslations } from '@/context/TranslationsContext';
import { useGlobal } from '@/context/GlobalContext';

export const PolyglotPresenterTTS = ({ className }: { className?: string }) => {
  const { translationsWithoutDetected } = useTranslations();
  const { activeSession, portal } = usePolyglotPresenter();
  const { apiServer } = useGlobal();

  const [isAvailable, setIsAvailable] = useState(portal?.ttsState === 'playing');
  const [playbackRate, setPlaybackRate] = useState<number | undefined>(undefined);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const hlsRef = useRef<Hls | null>(null);

  const translation = translationsWithoutDetected?.[0];
  const uuid = activeSession?.activeRequest?.id;
  const src = translation && uuid && `${apiServer}/stream-tts/${uuid}/${translation}/stream.m3u8`;

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!src) return;

      if (['done', 'error'].includes(String(activeSession?.activeRequest?.status))) {
        clearInterval(interval);
        console.log('Session is finished. TTS is not available');
        return;
      }

      try {
        const response = await fetch(src, { method: 'HEAD' });
        setIsAvailable(response.ok);
        if (response.ok) {
          clearInterval(interval);
          console.log('✅ TTS is available');
        }
      } catch (e) {
        console.log('Waiting for TTS to be available');
        setIsAvailable(false);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [src, activeSession?.activeRequest?.status]);

  useEffect(() => {
    if (!audioRef.current || !isAvailable || !src) return;

    const audio = audioRef.current;

    if (Hls.isSupported()) {
      const hls = new Hls({
        liveSyncDuration: 1,
        liveMaxLatencyDuration: 60,
      });
      hlsRef.current = hls;

      hls.loadSource(src);
      hls.attachMedia(audio);

      hls.on(Hls.Events.FRAG_CHANGED, (_, data) => {
        portal?.setCurrentSegment(Number(data.frag.sn));
      });

      hls.on(Hls.Events.ERROR, (_, data) => {
        console.log(data);
        setPlaybackRate(1.0);
      });
    } else if (audio.canPlayType('application/vnd.apple.mpegurl')) {
      audio.src = src;
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, isAvailable]);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleLoadedMetadata = () => {
      if (portal?.ttsState === 'playing') {
        audio.play().catch((err) => console.warn('Auto-play error:', err));
      }
    };

    const handlePlay = () => {
      portal?.setTtsState?.('playing');
    };

    const handlePause = () => {
      portal?.setTtsState?.('paused');
    };

    const handleTimeUpdate = () => {
      const currentTime = audio.currentTime;
      portal?.setTtsCurrentTime?.(currentTime);
    };

    const handleError = () => {
      console.log('Error playing TTS:');
      setPlaybackRate(1.0);
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('error', handleError);

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('error', handleError);
    };
  }, [portal]);

  useEffect(() => {
    const checkLag = () => {
      const audio = audioRef.current;
      if (!audio) return;

      if (audio.buffered.length > 0) {
        const bufferedEnd = audio.buffered.end(audio.buffered.length - 1);
        const currentTime = audio.currentTime;
        const lag = bufferedEnd - currentTime;
        if (lag > 60) {
          setPlaybackRate(1.35);
        } else if (lag > 30) {
          setPlaybackRate(1.2);
        } else {
          setPlaybackRate(undefined);
        }
      }
    };

    const lagInterval = setInterval(checkLag, 5000);
    return () => clearInterval(lagInterval);
  }, []);

  useEffect(() => {
    console.log(`Changed playback Speed: ${playbackRate || 'default'}`);
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate ?? 1.0;
    }
  }, [playbackRate]);

  if (!isAvailable) {
    return null;
  }

  return (
    <div className={classNames('flex h-full items-center justify-center gap-4', className)}>
      <audio ref={audioRef} className="!h-8 w-96" controls autoPlay={portal?.ttsState === 'playing'} />
    </div>
  );
};
